<template>
    <Toast />
    <div id="container" class="container">
        <div class="db-header">
            <h1>Derulo - {{ this.$store.getters.appVersion }}</h1>
            <small v-if="this.mode === 'development'"> {{ this.url }}</small>
        </div>
        <TabView>
            <TabPanel header="Bulk Create">
                <div class="mainSection">
                    <div class="section">
                        <div class="label-text-group center">
                            <label class="label"><b>Start Date:</b></label>
                            <Calendar class="inputtext" type="text" v-model="this.startDate" />
                        </div>
                    </div>
                    <div class="section">
                        <div class="label-text-group center">
                            <label class="label"><b>End Date:</b></label>
                            <Calendar class="inputtext" type="text" v-model="this.endDate" />
                        </div>
                    </div>
                    <div class="section">
                        <div class="label-text-group center">
                            <label class="label"><b>Producer: (optional)</b></label>
                            <input class="inputtext" type="text" v-model="this.producer" />
                        </div>
                    </div>
                    <div class="section">
                        <div class="label-text-group center">
                            <Button class="button" label="Get Prework Data" @click="this.getPreWork()" />
                            <Button v-if="this.preWorkManual" class="button" label="Create JSONs"
                                @click="this.createBulkJsons()" />
                            <Button v-if="this.preWorkManual" class="button" label="Export"
                                @click="this.export()"/>
                        </div>
                        <div class="label-text-group center">
                            <span v-if="this.errors" class="error">Failed: {{ this.errors }}</span>
                        </div>
                    </div>
                    <div class="section">
                        <div class="label-text-group center">
                            <ProgressBar :value="this.progress" v-if="this.progress > 0" />
                        </div>
                    </div>
                    <div class="section">
                        <div class="label-text-group center">
                            <DataTable v-if="this.preWorkManual" :value="this.preWorkManual" paginator :rows="10"
                                class="sm" :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows
                                editMode="cell" tableStyle="font-size: large"
                                @cell-edit-complete="onCellEditComplete($event)" :pt="{
                column: {
                    bodycell: ({ state }) => ({
                        class: [{ 'pt-0 pb-0': state['d_editing'] }]
                    })
                }
            }">
                                <template #header>
                                    <div>
                                        <h2>Manual ({{ this.manualCount }})</h2>
                                    </div>
                                </template>
                                <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
                                    sortable>
                                    <template #editor="{ data, field }">
                                        <InputText v-model="data[field]" autofocus />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="section">
                        <div class="label-text-group center">
                            <DataTable v-if="this.preWorkAutomated" :value="this.preWorkAutomated" paginator :rows="10"
                                class="sm" :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows
                                tableStyle="font-size: large">
                                <template #header>
                                    <div>
                                        <h2>Automated ({{ this.automatedCount }})</h2>
                                    </div>
                                </template>
                                <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
                                    sortable />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Single Create">
                <div class="mainSection">
                    <div class="section">
                        <div class="label-text-group center">
                            <label class="label"><b>Unique Entity:</b></label>
                            <InputText class="inputtext" type="text" v-model="this.uniqEntity" />
                        </div>
                    </div>
                    <div class="section">
                        <div class="label-text-group center flex flex-column gap-2">
                            <label class="label"><b>Expiration Date:</b></label>
                            <Calendar class="inputtext" type="text" v-model="this.expirationDate" />
                        </div>
                    </div>
                    <div class="center">
                        <Button class="button" label="Create JSON" @click="this.createJson()" />
                    </div>
                </div>
            </TabPanel>
        </TabView>
        <div id="spinner" class="spinner">
            <ProgressSpinner />
        </div>
    </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { DeruloRepository } from "@/repositories/deruloRepository.js";
import Enumerable from 'linq';
import { asyncParallelForEach, BACK_OFF_RETRY } from 'async-parallel-foreach';
import { watch } from 'vue';
import { useIdle } from '@vueuse/core';
import { PublicClientApplication } from '@azure/msal-browser';
import writeXlsxFile from 'write-excel-file';

export default {
    name: 'Dashboard',
    data() {
        return {
            toast: useToast(),
            uniqEntity: undefined,
            expirationDate: undefined,
            preWorkManual: undefined,
            preWorkAutomated: undefined,
            manualCount: 0,
            automatedCount: 0,
            startDate: undefined,
            endDate: undefined,
            progress: 0,
            errors: undefined,
            isBusy: false,
            count: 0,
            producer: undefined,
            batchId: 0,
            mode: process.env.VUE_APP_MODE,
            url: process.env.VUE_APP_API_URL,
            columns: [
                { field: 'accountName', header: "Account Name" },
                { field: 'uniqEntity', header: "Unique Entity" },
                { field: 'expirationDate', header: "Calculated Exp. Date" },
                { field: 'producer', header: "Producer" }
            ]
        }
    },
    created() {
        this.$emitter.on("login", () => {
            this.showContainer();
        }),
            this.$emitter.on("logout", () => {
                this.hideContainer();
            }),
            this.$emitter.on("progressChange", () => {
                this.progress = +((this.count / (this.manualCount + this.automatedCount)) * 100).toFixed(2);
                this.count++;
            });
    },
    async mounted() {
        this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig);
        this.createIdleWatcher();
        this.hideSpinner();
        this.hideContainer();
    },
    methods: {
        async export() {
            var rows = new Array();
            var headerRow = [
                {
                    value: 'ACCOUNT NAME'
                },
                {
                    value: 'UNIQUE ENTITY'
                },
                {
                    value: 'CALC. EXP. DATE'
                },
                {
                    value: 'PRODUCER'
                },
                {
                    value: 'STATUS'
                }
            ]

            var allApps = Enumerable.from(this.preWorkAutomated.concat(this.preWorkManual)).orderBy(x => x.status);

            allApps.forEach(element => {
                var row = [
                    {
                        type: String,
                        value: element.accountName
                    },
                    {
                        type: Number,
                        value: element.uniqEntity
                    },
                    {
                        type: String,
                        value: element.expirationDate
                    },
                    {
                        type: String,
                        value: element.producer
                    },
                    {
                        type: String,
                        value: element.status
                    }
                ]

                rows.push(row);
            });

            var data = [
                headerRow
            ]

            rows.forEach(element => {
                data.push(element);
            })

            await writeXlsxFile(data,
                {
                    fileName: "derulo.xlsx"
                });
        },
        createIdleWatcher() {
            const { idle, reset } = useIdle(240 * 60 * 1000); //4 hours
            watch(idle, async (idleValue) => {
                if (idleValue && !this.isBusy) {
                    this.$store.commit("setAccount", undefined);
                    this.$store.dispatch("signout");
                    this.$emitter.emit('logout', 'logging out');
                    reset();
                }
            })
        },
        hideSpinner() {
            var spinner = document.getElementById("spinner");
            spinner.style.display = "none";
        },
        showSpinner() {
            var spinner = document.getElementById("spinner");
            spinner.style.display = "block";
        },
        hideContainer() {
            var container = document.getElementById("container");
            container.style.display = "none";
        },
        showContainer() {
            var container = document.getElementById("container");
            container.style.display = "block";
        },
        async createBulkJsons() {
            if (this.isBusy) {
                return;
            }

            await this.insertBatchHistory();
            //reset progress info
            this.progress = 0;
            this.count = 1;
            this.isBusy = true;

            this.errors = undefined;
            var deruloRepository = new DeruloRepository();
            var all = Array.from(this.preWorkAutomated).concat(Array.from(this.preWorkManual));
            var errors = new Array();

            await asyncParallelForEach(all, 5, async (element) => {
                this.$emitter.emit('progressChange');

                try {
                    await deruloRepository.insertDerulo(element.uniqEntity, new Date(element.expirationDate), new Date(element.originalExpirationDate), this.batchId);

                }
                catch (err) {
                    errors.push(element.uniqEntity);
                }
            },
                {
                    times: 5,
                    interval: BACK_OFF_RETRY.exponential()
                });

            await this.updateBatchHistory(all.length, errors.length);
            this.errors = errors.join(", ");
            this.isBusy = false;

            this.toast.add({ severity: "info", summary: "Completed", detail: "Process has completed.", life: 5000 });
        },
        async createJson() {
            if (this.isBusy) {
                return;
            }

            this.isBusy = true;

            if (this.uniqEntity === undefined || this.expirationDate === undefined) {
                this.toast.add({ severity: "error", summary: "Missing Data", detail: "Unique Entity and/or Expiration Date is missing.", life: 5000 });
                return;
            }

            this.showSpinner();

            try {
                await this.insertBatchHistory();
                var repo = new DeruloRepository();
                await repo.insertDerulo(this.uniqEntity, this.expirationDate, this.expirationDate, this.batchId);
                await this.updateBatchHistory(1, 0);
                this.toast.add({ severity: "info", summary: "Success", detail: "JSON successfully processed.", life: 5000 });
            }
            catch (error) {
                await this.updateBatchHistory(0, 1);
                console.log(error);
                this.toast.add({ severity: "error", summary: "Error Creating Json", detail: error?.response?.data?.title, life: 5000 });
            }
            finally {
                this.hideSpinner();
                this.isBusy = false;
            }
        },
        async getPreWork() {
            if (this.isBusy) {
                return;
            }

            this.isBusy = true;
            this.progress = 0;

            if (this.startDate === undefined || this.endDate === undefined) {
                this.toast.add({ severity: "error", summary: "Missing Data", detail: "Start and/or End Date is missing.", life: 5000 });
                return;
            }

            if (this.startDate > this.endDate) {
                this.toast.add({ severity: "error", summary: "Invalid Range", detail: "Date range is invalid.", life: 5000 });
                return;
            }

            this.showSpinner();

            try {
                var repo = new DeruloRepository();
                var data = await repo.getPrework(this.startDate, this.endDate, this.producer);
                this.preWorkAutomated = Enumerable.from(data).where(x => x.status === "automated").toArray();
                this.preWorkManual = Enumerable.from(data).where(x => x.status === "manual").toArray();
                this.automatedCount = Array.from(this.preWorkAutomated).length;
                this.manualCount = Array.from(this.preWorkManual).length;
            }
            catch (err) {
                this.toast.add({ severity: "error", summary: "Error Getting Prework Data", detail: err, life: 5000 });
            }

            this.hideSpinner();
            this.isBusy = false;
        },
        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            if (field === 'expirationDate') {
                data[field] = newValue;
            }
            else {
                this.toast.add({ severity: "warn", summary: "Warning", detail: "Cannot edit this field. Only expiration date can be edited.", life: 5000 });
            }
        },
        async insertBatchHistory() {
            try {
                var deruloRepository = new DeruloRepository();
                await this.$msalInstance.initialize();
                const myAccounts = this.$msalInstance.getAllAccounts();
                var account = myAccounts[0];

                this.batchId = await deruloRepository.insertDeruloBatchHistory(account.username, new Date());

            }
            catch (error) {
                console.log(error);
                this.toast.add({ severity: "error", summary: "Error Inserting Batch History", detail: error?.response?.data?.title, life: 5000 });
            }
        },
        async updateBatchHistory(processed, errors) {
            var deruloRepository = new DeruloRepository();

            try {
                await deruloRepository.updateDeruloBatchHistory(this.batchId, new Date(), processed, errors);
            }
            catch (error) {
                console.log(error);
                this.toast.add({ severity: "error", summary: "Error Updating Batch History", detail: error?.response?.data?.title, life: 5000 });
            }
        }
    }
}
</script>

<style scoped>
.spinner {
    margin-top: 75px;
    text-align: center;
}

.db-header {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
    font-size: large;
}

.container {
    margin-top: 75px;
}

.inputtext,
.p-inputtext {
    width: 300px;
    font-size: large !important;
}

.button {
    margin: 10px;
    font-size: large !important;
}

.error {
    color: red;
    margin: 10px;
}
</style>