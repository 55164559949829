import { createStore } from "vuex";
import { BrowserCacheLocation } from "@azure/msal-browser";

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: "5e8d6c21-3e21-41ea-8585-425b7f12f3e7",
          redirectUri: "",
          postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI,
          authority:
            "https://login.microsoftonline.com/a3426403-026c-4ede-a083-96586ce500c0",
        },
        cache: {
          cacheLocation: BrowserCacheLocation.SessionStorage
        },
      },
      accessToken: "",
      account: undefined,
      apiToken: "",
      apiTokenExp: "",
      packageVersion: process.env.PACKAGE_VERSION || "0",
    };
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setApiToken(state, apiToken) {
      state.apiToken = apiToken;
    },
    setApiTokenExp(state, apiTokenExp) {
      state.apiTokenExp = apiTokenExp;
    },
  },
  getters: {
    account: (state) => {
      return state.account;
    },
    accessToken: (state) => {
      return state.accessToken;
    },
    apiToken: (state) => {
      return state.apiToken;
    },
    apiTokenExp: (state) => {
      return state.apiTokenExp;
    },
    appVersion: (state) => {
      return state.packageVersion;
    },
  },
  actions: {
    signout({ state }) {
      console.log("signout called in store");
      state.accessToken = "";
      state.account = undefined;
      state.apiToken = "";
      state.apiTokenExp = "";
    },
  },
});

export default store;
