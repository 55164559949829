import { PublicClientApplication } from '@azure/msal-browser'
import store from '@/store/store.js'

export default class MsalLibrary {
  constructor() {}

  async initialize() {
    this.$msalInstance = new PublicClientApplication(store.state.msalConfig)
    await this.$msalInstance.initialize()
    const accounts = this.$msalInstance.getAllAccounts()
    if (accounts.length == 0) {
      return
    }

    store.account = accounts[0]

    //get the access token for the logged in user
    var tokenRequest = {
      scopes: ['user.read'],
      forceRefresh: true,
      account: store.account
    }

    try {
      await this.$msalInstance.acquireTokenSilent(tokenRequest).then(async (response) => {
        store.commit('setAccessToken', response.accessToken)
      })
    } catch (err) {
      console.log(err)
      await this.silentSignOut()
      this.$emitter.emit('logout', 'logging out')
    }
  }

  async signIn() {
    await this.$msalInstance.handleRedirectPromise()

    await this.$msalInstance
      .loginPopup({})
      .then(async () => {
        const myAccounts = this.$msalInstance.getAllAccounts()
        store.account = myAccounts[0]
      })
      .catch((error) => {
        console.error(`error during authentication: ${error}`)
      })

    //get the access token for the logged in user
    var tokenRequest = {
      scopes: ['user.read'],
      forceRefresh: true,
      account: store.account
    }

    await this.$msalInstance.acquireTokenSilent(tokenRequest).then((response) => {
      store.commit('setAccessToken', response.accessToken)
    })
  }

  async signOut() {
    await this.$msalInstance
      .logoutRedirect({ account: store.account })
      .then(() => {
        store.commit('setAccount', undefined)
        store.dispatch('signout')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  async silentSignOut() {
    if (!store.account) {
      const myAccounts = this.$msalInstance.getAllAccounts()
      store.account = myAccounts[0]
      await this.$msalInstance.logoutRedirect({ account: store.account })
      store.commit('setAccount', undefined)
      store.dispatch('signout')
    }
  }
}
