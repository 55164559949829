import { createApp } from 'vue'
import App from './App.vue'
import Emitter from "tiny-emitter";
import store from "@/store/store.js";
import Primevue from "primevue/config";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import ProgressSpinner from "primevue/progressspinner";
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import ProgressBar from 'primevue/progressbar';
import MsalLibrary from '@/msal/msalLibrary.js';
import "./assets/css/app.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primevue/resources/themes/saga-green/theme.css";

const app = createApp(App);
app.config.globalProperties.$msalLibrary = new MsalLibrary();
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Calendar", Calendar);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Toast", Toast);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Row", Row);
app.component("ProgressBar", ProgressBar);
app.use(store);
app.use(Primevue);
app.use(ToastService);
app.mount("#app");