<template>
    <header class="header">
        <img alt="rp" src="@/assets/reliance-logo-white.svg" />
        <div class="nav">
            <Button @click="processClick()">{{ this.buttonContent }}</Button>
        </div>
    </header>
</template>

<script>
export default {
    name: 'AppHeader',
    data() {
        return {
            buttonContent: "Sign In"
        };
    },
    async created() {
        this.$emitter.on('login', (account) => {
            this.buttonContent = "Welcome, " + account.name;
        });
        this.$emitter.on('logout', () => {
            this.buttonContent = "Sign In";
        });
    },
    async mounted() {
        await this.$msalLibrary.initialize();
        this.$emitter.emit('login', this.$store.account)
    },
    methods: {
        async processClick() {
            if (this.$store?.account != undefined) {
                await this.$msalLibrary.signOut();
                this.$emitter.emit('logout', 'logging out');
            }
            else {
                await this.$msalLibrary.signIn();
                this.$emitter.emit('login', this.$store.account);
            }
        }
    }
}
</script>

<style scoped>
.p-button {
    background: #073C3E;
    border-color: #073C3E;
    color: #FFFFFF;
}
</style>