import BaseRepository from "@/repositories/baseRepository.js";

export class DeruloRepository extends BaseRepository {
    constructor() {
        super();
    }

    async insertDerulo(uniqEntity, expirationDate, originalExpirationDate, batchId){
        let params = {
            UniqEntity: uniqEntity,
            ExpirationDate: expirationDate,
            OriginalExpirationDate: originalExpirationDate,
            DeruloBatchHistoryId: batchId
        }

        return await super.postWithBody("/api/Derulo/insertderulo", params);
    }

    async getPrework(startDate, endDate, producer){
        let params = {
            StartDate: startDate,
            EndDate: endDate,
            Producer: producer
        }

        return await super.postWithBody("/api/Derulo/getderuloprework", params);
    }

    async insertDeruloBatchHistory(email, startDate) {
        let params = {
            UserEmail: email,
            StartDate: startDate
        }

        return await super.postWithBody("/api/DeruloHistory/insertderulobatchhistory", params);
    }

    async updateDeruloBatchHistory(batchId, endDate, processed, errors){
        let params = {
            Id: batchId,
            EndDate: endDate,
            Processed: processed,
            Errors: errors
        }

        return await super.postWithBody("/api/DeruloHistory/updatederulobatchhistory", params);
    }
}